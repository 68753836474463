import Link from 'next/link';

import { MainLayout } from '@/widgets/layouts/main';
import { CallToAction } from '@/widgets/layouts/main/call-to-action';

import { TextGradient } from '@/shared/components/text-gradient';
import { Button } from '@/shared/components/ui/button';
import { Paper } from '@/shared/components/ui/paper';

export const NotFoundPage = () => {
  return (
    <Paper className="mt-[69px] flex py-4" variant="dot">
      <div className="container min-h-full">
        <div className="flex flex-col items-center justify-center">
          <TextGradient className="text-center text-3xl xs:text-5xl md:text-7xl lg:text-8xl" tag="h1" text="404" />

          <p className="mb-6 text-center text-muted-foreground">Такой страницы не существует</p>

          <Link href="/" aria-label="На главную">
            <Button aria-label="На главную">На главную</Button>
          </Link>
        </div>

        <div className="w-full ">
          <CallToAction header="Хотите учиться?" withForm />
        </div>
      </div>
    </Paper>
  );
};

NotFoundPage.Layout = MainLayout;
